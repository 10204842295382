// DFieldConfig

<template>
  	<v-container fluid grid-list-sm>
		<v-card class="overflow-hidden">
			<v-app-bar
				absolute
				dark dense
				scroll-target="#playground"
				color="indigo lighten-1"
				elevate-on-scroll
			>
				<v-toolbar-title>{{ title }} </v-toolbar-title>
				<v-btn small @click="selectorStart" class="ml-3" color="indigo">Auswahl</v-btn>
				<v-spacer/>
				<v-btn small @click="changeView" class="ml-3" color="indigo">Build</v-btn>
	  		</v-app-bar>
			<v-sheet
				id="playground"
				class="overflow-y-auto"
				max-height="800"
			>
				<v-container fluid style="min-height: 700px;padding-top: 50px">
					<v-row v-if="dtable.hasData">
						<v-col cols="12" sm="6" class="pa-0"></v-col>
						<v-col cols="12" sm="1" class="pa-0"><v-text-field class="myinput"
								value="Flen" dense solo hide-details readonly filled
						></v-text-field></v-col>
						<v-col cols="12" sm="1" class="pa-0"><v-text-field class="myinput"
								value="FReq" dense solo hide-details readonly filled
						></v-text-field></v-col>
						<v-col cols="12" sm="1" class="pa-0"><v-text-field class="myinput"
								value="Access" dense solo hide-details readonly filled
						></v-text-field></v-col>
						<v-col cols="12" sm="1" class="pa-0"><v-text-field class="myinput"
								value="FRead" dense solo hide-details readonly filled
						></v-text-field></v-col>
						<v-col cols="12" sm="1" class="pa-0"><v-text-field class="myinput"
								value="FRead" dense solo hide-details readonly filled
						></v-text-field></v-col>
					</v-row>
					<v-row v-for="(item, index) in dfields" :key="index">
						<v-col cols="12" sm="1" class="pa-0">
							<v-text-field class="myinput"
								:value="item.fpos" dense solo hide-details readonly filled reverse
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="1" class="pa-0">
							<v-text-field class="myinput"
								:value="item.dfield" dense solo hide-details readonly filled
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="1" class="pa-0">
							<v-text-field class="myinput"
								:value="item.dtype" dense solo hide-details readonly filled
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="2" class="pa-0">
							<v-text-field class="myinput" autocomplete="new-password"
								v-model="item.fname" :ref="'tfname'+index" label="FName" dense solo hide-details filled
								@keyup.enter="tabToNext('tftype'+index)"
								@keyup.down="tabToNext('tfname'+(index+1))"
								@keyup.up="tabToNext('tfname'+(index-1))"
								@keydown="hasChanged"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="1" class="pa-0">
							<v-text-field class="myinput" autocomplete="new-password"
								v-model="item.ftype" :ref="'tftype'+index" label="FType" dense solo hide-details filled
								@keyup.enter="tabToNext('tflen'+(index))"
								@keyup.down="tabToNext('tftype'+(index+1))"
								@keyup.up="tabToNext('tftype'+(index-1))"
								@keydown="hasChanged"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="1" class="pa-0">
        					<v-tooltip top open-delay="1000"><template v-slot:activator="{ on }">
							<v-text-field class="myinput" autocomplete="new-password" v-on="on"
								v-model="item.flen" :ref="'tflen'+index" label="FLen" dense solo hide-details filled
								@keyup.enter="tabToNext('tfreq'+(index))"
								@keyup.down="tabToNext('tflen'+(index+1))"
								@keyup.up="tabToNext('tflen'+(index-1))"
								@keydown="hasChanged"
							></v-text-field>
          					</template><span>FLen</span></v-tooltip>
						</v-col>
						<v-col cols="12" sm="1" class="pa-0">
        					<v-tooltip top open-delay="1000"><template v-slot:activator="{ on }">
							<v-text-field class="myinput" autocomplete="new-password" v-on="on"
								v-model="item.freq" :ref="'tfreq'+index" label="FReq" dense solo hide-details filled
								@keyup.enter="tabToNext('tfname'+(index+1))"
								@keyup.down="tabToNext('tfreq'+(index+1))"
								@keyup.up="tabToNext('tfreq'+(index-1))"
								@keydown="hasChanged"
							></v-text-field>
          					</template><span>FReq</span></v-tooltip>
						</v-col>
						<v-col cols="12" sm="1" class="pa-0">
        					<v-tooltip top open-delay="1000"><template v-slot:activator="{ on }">
							<v-text-field class="myinput" autocomplete="new-password" v-on="on"
								v-model="item.access" :ref="'taccess'+index" label="Access" dense solo hide-details filled
								@keyup.enter="tabToNext('tfread'+(index+1))"
								@keyup.down="tabToNext('taccess'+(index+1))"
								@keyup.up="tabToNext('tfaccess'+(index-1))"
								@keydown="hasChanged"
							></v-text-field>
          					</template><span>Access</span></v-tooltip>
						</v-col>
						<v-col cols="12" sm="1" class="pa-0">
        					<v-tooltip top open-delay="1000"><template v-slot:activator="{ on }">
							<v-text-field class="myinput" autocomplete="new-password" v-on="on"
								v-model="item.fread" :ref="'tfread'+index" label="FRead" dense solo hide-details filled
								@keyup.enter="tabToNext('tfname'+(index+1))"
								@keyup.down="tabToNext('tfread'+(index+1))"
								@keyup.up="tabToNext('tfread'+(index-1))"
								@keydown="hasChanged"
							></v-text-field>
          					</template><span>FRead</span></v-tooltip>
						</v-col>
						<v-col cols="12" sm="1" class="pa-0">
						    <v-switch v-model="item.fread" dense solo hide-details filled color="success"
							 class="align-center justify-center mt-1"></v-switch>				
						</v-col>
					</v-row>
		  		</v-container>
			</v-sheet>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="success" :disabled="!dtable.isDirty || !dtable.hasData" @click="onSubmit()">Save</v-btn>
			</v-card-actions>
		</v-card>
    	<ItemSelector :model="selector" @selector-cancelled="selectorCancelled()"
									@selector-submitted="selectorSubmitted(...arguments)" >
    	</ItemSelector>
        <v-overlay absolute :value="loading">
        	<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>
        </v-overlay>
	</v-container>
</template>

<script>
	import ItemSelector from './ItemSelector'
	export default {
		name: 'test1',
		components: {
			ItemSelector,
		},
	  	props: {
	  		dfields: {
	  			type: Array,
	  			default () {
	  				return {}
	  			}
			},
			dtable: {
	  			type: Object,
	  			default () {
	  				return {}
	  			}
			},
	  	},
		data () {
			return {
				selector: {},
				loading: false,
			}
		},
		computed: {
			title: function() {
				return 'Tabelle: ' + (this.dtable.name ? this.dtable.name : '...')
			},
		},
		mounted() {
		},
		methods: {
			tabToNext(nextref) {
				if(this.$refs[nextref]) this.$refs[nextref][0].focus()
			},
			up(id) {
				const drow = this.items.find(x => x.id == id).drow
				this.items.find(x => x.id == id).drow = drow-10
				this.items.find(x => x.drow == drow-10).drow = drow
				this.sortData ()
			},
			selectorStart() {
				this.selector = { 'dialog': true }
			},
			selectorCancelled() {
				this.selector = {}
			},
			selectorSubmitted: function(selectedItemName) {
				this.selector = {}
				this.loading = true
				setTimeout(() => {
					this.dtable.name = selectedItemName
					if(this.dtable.name) {
						this.dtable.hasData = true
					} else {
						this.dtable.hasData = false
					}
					this.loading = false
				}, 500)
			},
			hasChanged(event) {
				if(!['Enter', 'ArrowUp', 'ArrowDown'].includes(event.key)) this.dtable.isDirty = true
			},
			onSubmit() {
				this.$emit('submitted')
			},
            changeView() {
                this.dtable.view = "Build"
                //console.log('change to build')
            },
		},
		watch: {
            dtable: {
                handler (val) {
                    //console.log('watch');
                    //console.log(val.name);
                },
                deep: true,
            },
		},
	}
</script>

<style>
    .myinput {
        height: 40px;
        font-size: 10pt;
    }
    .myinput label[for] {
        height: 40px;
        font-size: 10pt;
		color: black;
    }
    .myinput input[readonly] {
        color: rgb(153, 223, 255) !important;
    }
</style>